import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as mediaManagementAction from "../../redux/store-sarang/media/media-management.store";

import dayjs from 'dayjs';
import ReactPlayer from 'react-player/lazy';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate asc",
    orderBy: "modifiedDate",
    order: "asc",
}

export default function MediaList() {
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    // -- Data to map with ids

        // -- End Data to map with ids

    const [data, setData] = useState([])
    const [totalItemCount, setTotalItemCount] = useState(0);

    useEffect(() => {
        getListMediaManagement();
    }, []);

    const getListMediaManagement = (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression) => {
        showLoading(true);
        // setPage(pageIndex-1)
        mediaManagementAction.GetListMedia(pageIndex, pageSize, sortExpression).then(
            (res) => {
                if (res &&
                    res.content &&
                    res.content.items
                ) {
                    setData(res.content.items)
                    setTotalItemCount(res.content.totalItemCount)
                }
                showLoading(false);
            },
            (err) => {
                showLoading(false);
                err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        );
    }
    
    return (
        <div className="row">
            {
                data.length > 0 && data.map((row, index) =>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="card">
                            <h5 className="card-header">{ index+1}. {row.title}</h5>
                            <div className="card-body">
                                <ReactPlayer
                                    url={row.media_Url}
                                    config={{
                                        youtube: {
                                            playerVars: {
                                                showInfo: 1
                                            }
                                        }
                                    }}
                                    playing={false}
                                    controls={true}
                                    width='100%'
                                //height='50px'
                                />
                            </div>
                        </div>

                    </div>
                )
            }
        </div>
    )
}