import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListHerdsman = (pageIndex = 1, pageSize = 10, sortExpression = "", searchData=undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    searchData?.name && params.append("name", searchData.name);
    searchData?.departmentId && params.append("departmentId", searchData.departmentId);
    searchData?.zionId && params.append("zionId", searchData.zionId);
    searchData?.districtId && params.append("districtId", searchData.districtId);
    searchData?.address && params.append("address", searchData.address);
    searchData?.groupId1 && params.append("groupId1", searchData.groupId1);
    searchData?.email && params.append("email", searchData.email);
    searchData?.phoneNumber && params.append("phoneNumber", searchData.phoneNumber);
    searchData?.positionId && params.append("positionId", searchData.positionId);
    sortExpression && params.append("sorting", sortExpression);

    return service.get(ApiUrl.GetListHerdsman, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailHerdsman = (id) => {
    return service.get(ApiUrl.GetDetailHerdsman.replace("{id}",id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateHerdsman = (data) => {
    return service.post(ApiUrl.CreateHerdsman, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateHerdsman = (data) => {
    return service.put(ApiUrl.UpdateHerdsman, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteHerdsman = (id) => {
    return service.delete(ApiUrl.DeleteHerdsman.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentHerdsman = (id) => {
    return service.delete(ApiUrl.DeletePermanentHerdsman.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveHerdsman = (id) => {
    return service.put(ApiUrl.ActiveHerdsman.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveHerdsman = (id) => {
    return service.put(ApiUrl.DeActiveHerdsman.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const GetLookupHerdsman = (permission = undefined) => {
    const params = new URLSearchParams();
    permission!==undefined && params.append("permission", permission);
    return service.get(ApiUrl.GetLookupHerdsman, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetLookupHerdsmanGV = () => {
    return service.get(ApiUrl.GetLookupHerdsmanGV).then((res) => { return res }).catch((err) => { throw err });
}