import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import headCellsListCuisineOrder from './head-cell-list-cuisine-order';
import DataTableSarang from '../../components/datatable-sarang';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as cuisineOrderManagementAction from "../../redux/store-sarang/cuisine-order/cuisine-order-management.store";
import * as districtManagementAction from "../../redux/store/district-management/district.store"
import * as provinceManagementAction from "../../redux/store/province-management/province.store"
import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as communeManagementAction from "../../redux/store/commune-management/commune.store"

import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import FormAddEditCuisineOrder from './components/form-add-edit-cuisine-order';
import dayjs from 'dayjs';
import Select from "react-select";
import { optionsCuisineOrderStatus, optionsReceiptTime } from '../../common/constant';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import {
    getUserInfo,
} from "../../utils/configuration";
import DepartmentTreeSelect from '../list-department/components/department-tree-select';
import DepartmentHelper from '../list-department/helper/department-helper';
import { useMediaQuery } from 'react-responsive';
import DataTablePagination from '../../components/datatable-sarang/datatable-pagination';
import FormSearchWrapperSarang, { FormSearchFunctionButtons } from '../../components/form-search-wrapper-sarang/form-search-wrapper-sarang';
import MobileHeaderSarang from '../../components/mobile-header/mobile-header-sarang';


const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "orderDate desc" + (new Date().getHours() <= 13 ? ', receiptTime asc' : ', receiptTime desc'),
    orderBy: "orderDate",
    order: "desc",
}

export default function CuisineOrderList() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
    const now = new Date();
    
    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    // -- Data to map with ids
    const [district, setDistrict] = useState([]);
    const [commune, setCommune] = useState([]);
    const [herdsman, setHerdsman] = useState([]);
    const [herdsmanAll, setHerdsmanAll] = useState([]);
    const [herdsmanGv, setHerdsmanGv] = useState([]);
    const [user, setUser] = useState(getUserInfo());
    const [department, setDepartment] = useState([]);
    const [departmentAll, setDepartmentAll] = useState([]);
    const [searchData, setSearchData] = useState();
    const [sumaryCuisine, setSumaryCuisine] = useState();
    const [openSearch, setOpenSearch] = useState(false);
    const [receiptTimeOrder, setReceiptTimeOrder] = useState(new Date().getHours() <= 13 ? ', receiptTime asc' : ', receiptTime desc');

    const getLookupCommune = async (districtId) => {
        try {
            const res = await communeManagementAction.GetLookupCommune(districtId);
            if (res && res.content) {
                setCommune(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    const getLookupDistrict = async () => {
        try {
            const res = await districtManagementAction.GetLookupDistrict();
            if (res && res.content) {
                setDistrict(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    //const getLookupProvince = async () => {
    //    try {
    //        const res = await provinceManagementAction.GetLookupProvince();
    //        if (res && res.content) {
    //            setProvince(res.content);
    //        }
    //    } catch (err) {
    //        throw err;
    //    }
    //};

    const getLookupHerdsman = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman();
            if (res && res.content) {
                setHerdsman(res.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const getLookupHerdsmanGV = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsmanGV();
            if (res && res.content) {
                setHerdsmanGv(res.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const getLookupHerdsmanAll = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman(false);
            if (res && res.content) {
                setHerdsmanAll(res.content);
            }
        } catch (err) {
            throw err;
        }
    }

    //const getLookupDepartment = async () => {
    //    try {
    //        const res = await departmentManagementAction.GetLookupDepartment();
    //        if (res && res.content && res.content.items) {
    //            setDepartment(DepartmentHelper.PrepareSortData(res.content.items));
    //        }
    //    } catch (err) {
    //        throw err;
    //    }
    //};

    const GetListNoPermiss = async () => {
        try {
            const res = await departmentManagementAction.GetListNoPermiss();
            if (res && res.content && res.content.items) {
                setDepartmentAll(DepartmentHelper.PrepareSortData(res.content.items));
            }
        } catch (err) {
            throw err;
        }
    };

    const fetchData = async () => {
        showLoading(true);
        try {
            await Promise.allSettled([
                getLookupDistrict(),
                //getLookupProvince(),
                getLookupHerdsman(),
                getLookupHerdsmanAll(),
                getLookupHerdsmanGV(),
                GetListNoPermiss(),
            ]);
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }
    
    // -- End Data to map with ids

    const [data, setData] = useState([])
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    useEffect(() => {
        setValue("orderFromDate", dayjs(now).format());
        setSearchData({ orderFromDate: dayjs(now).format() });
        getListCuisineOrderManagement(1, configLocal.defaultPageSize, configLocal.sortExpression, { orderFromDate: dayjs(now).format() });
        fetchData();
    }, []);

    useEffect(() => {
        if (!selectedDistrict) return;
        getLookupCommune(selectedDistrict.value)
    }, [selectedDistrict])

    const getListCuisineOrderManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, data = undefined) => {
        showLoading(true);
        try {
            const res = await cuisineOrderManagementAction.GetListCuisineOrder(pageIndex, pageSize, sortExpression, data);
    
            if (res && res.content && res.content.items) {
                setData(res.content.items);
                setTotalItemCount(res.content.totalItemCount);
            }

            if (res) {
                setSumaryCuisine({
                    sumaryLunch: res.sumaryLunch,
                    sumaryDinner: res.sumaryDinner,
                    sumaryMorning: res.sumaryMorning,
                    sumaryCuisineQuatity: res.sumaryCuisine,
                    sumaryMorningOrder: res.sumaryMorningOrder,
                    sumaryLunchOrder: res.sumaryLunchOrder,
                    sumaryDinnerOrder: res.sumaryDinnerOrder,
                    sumaryCuisineOrder: res.sumaryCuisineOrder
                });
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort + ((property && property != "receiptTime") ? receiptTimeOrder : '');
        getListCuisineOrderManagement(page + 1, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? receiptTimeOrder : '');
        getListCuisineOrderManagement(newPage, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? receiptTimeOrder : '');
        getListCuisineOrderManagement(1, event.target.value, sortExpression, searchData);
    };

    const handleSubmitAddEditCuisineOrder = async (data) => {
        showLoading(true);

        try {
            let res = null;

            if( !data.id ) {
                res = await cuisineOrderManagementAction.CreateCuisineOrder(data);
            } else {
                res = await cuisineOrderManagementAction.UpdateCuisineOrder(data);
            }

            if ( res && res.content ) {
                let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? receiptTimeOrder : '');
                getListCuisineOrderManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
        } catch (err) {
            showLoading(false);
            err  &&
            ShowNotification(
                err.errorMessage,
                NotificationMessageType.Error
            );
        }
    }
     
    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [isOpenChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
    const [isOpenChangeConfirmDialog, setOpenChangeConfirmDialog] = useState(false);
    const [isOpenChangeDeliveryDialog, setOpenChangeDeliveryDialog] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };
    const openEditDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setSelectedItem(null);
    }
    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setOpenChangeStatusDialog(false);
        setOpenChangeConfirmDialog(false);
        setOpenChangeDeliveryDialog(false);
        setSelectedItem(null);
    }

    const handleConfirm = async () => {
        try {
            let res = null;
            if( isOpenDeleteDialog ) {
                res = await cuisineOrderManagementAction.DeleteCuisineOrder(selectedItem.id);
            } else if (isOpenActiveDialog) {
                res = await cuisineOrderManagementAction.ActiveCuisineOrder(selectedItem.id);
            } else if (isOpenDeactiveDialog) {
                res = await cuisineOrderManagementAction.DeActiveCuisineOrder(selectedItem.id);
            } else if (isOpenChangeStatusDialog) {
                res = await cuisineOrderManagementAction.ChangeCuisineOrderStatus({ id: selectedItem.id, status: selectedItem.orderStatusChange });
            } else if (isOpenChangeConfirmDialog) {
                res = await cuisineOrderManagementAction.ChangeConfirm({ id: selectedItem.id, herdsmanId: user.herdsmanId }); //selectedItem.herdsManConfirmId
            } else if (isOpenChangeDeliveryDialog) {
                res = await cuisineOrderManagementAction.ChangeDelivery({ id: selectedItem.id, herdsmanId: selectedItem.herdsManDeliveryId });
            }

            if( res ) {
                let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? receiptTimeOrder : '');
                getListCuisineOrderManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }

            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            showLoading(false);
            err &&
            ShowNotification(
                err.errorMessage,
                NotificationMessageType.Error
            );
        }
    }

    const onSubmit = (data) => {
        setSearchData(data);
        let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? receiptTimeOrder : '');
        setPage(0);
        getListCuisineOrderManagement(1, rowsPerPage, sortExpression, data);
        if (isTabletOrMobile)
            setOpenSearch(false);
    }

    const renderTableForDesktop = () => {
        return <>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
            <DataTableSarang
                // button functions
                sumary={sumaryCuisine }
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                // head cells
                headCells={headCellsListCuisineOrder}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                // pagination
                totalItemCount={totalItemCount}
                setRowsPerPage={setRowsPerPage}
                pageIndex={page}
                handleChangePage={handleChangePage}
            >
                <tbody>
                    {
                        data.length > 0 ?
                        data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td><span>{row.orderCode}</span></td>
                                <td><span>{dayjs(row.orderDate).format("DD/MM/YYYY")}</span></td>
                                {/* <td><span>{row.receiptTime === 1 ? "Sáng" : (row.receiptTime === 2 ? "Trưa" : "Chiều")}</span></td> */}
                                <td><span>{optionsReceiptTime.find(item => item.value === row.receiptTime)?.label}</span></td>
                                <td><span>{row.quatity}</span></td>
                                <td><span>{row.herdsManOrderName}</span></td>
                                <td><span><a href={`tel:${row.herdsManOrderPhoneNumber}`}>{row.herdsManOrderPhoneNumber}</a></span></td>
                                <td><span>{row.deliveryType==0 ? "Giao nhận" : "Tự lấy"}</span></td>
                                {/*<td><span>{row.districtName}</span></td>*/}
                                <td><span>{row.address}</span></td>
                                <td>
                                    {
                                        //(row.orderStatus !== 20 && row.orderStatus !== 30) ? <span>{row.orderStatusName}</span> :
                                        (!row.allowChangeStatus) ? <span>{row.orderStatusName}</span> :
                                        <span>
                                            <Select
                                                value={row?.orderStatus ? {label: row.orderStatusName, value: row.orderStatus} : null}
                                                placeholder="Chọn Trạng thái"
                                                onChange={(data) => {
                                                    setOpenChangeStatusDialog(true);
                                                    openConfirmDialog({ ...row, orderStatusChange: data.value, orderStatusNameChange: data.label});
                                                }}
                                                // options={
                                                //     row.orderStatus < 40 ?
                                                //         optionsCuisineOrderStatus.filter(x => x.value <= 40 && x.value >= row?.orderStatus) :
                                                //         optionsCuisineOrderStatus.filter(x => x.value > 40 && x.value >= row?.orderStatus) 
                                                // }
                                                options={optionsCuisineOrderStatus.filter(x => ((x.value === 30 || x.value >= 50) && x.value !== row.orderStatus) && x.value >= row?.orderStatus)}
                                                noOptionsMessage={() => "Không tồn tại"}
                                                // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        </span>
                                    }
                                </td>                                    
                                <td>
                                    <span>
                                        {
                                            row.orderStatus >= 20 ?
                                                (
                                            <img title="Đã xác nhận" 
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick" onClick={
                                                    () => {
                                                    //setOpenDeactiveDialog(true);
                                                    //setOpenChangeConfirmDialog(true);
                                                    //openConfirmDialog(row);
                                                }}
                                            />
                                        ) : (
                                            <img title="Chưa xác nhận" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel" onClick={() => {
                                                        if (row.allowConfirm) {
                                                            setOpenChangeConfirmDialog(true);
                                                            openConfirmDialog(row);
                                                        }
                                                        else {
                                                            ShowNotification("Ae/Ce không có quyền xác nhận", NotificationMessageType.Warning);
                                                        }
                                                        
                                                    }}
                                            />
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <span>{row.herdsManConfirmName}</span>
                                    {
                                        //row.herdsManConfirmId ? 
                                        //<span>{row.herdsManConfirmName}</span>
                                        //: 
                                        //<span>
                                        //    <Select
                                        //        placeholder="Chưa xác nhận"
                                        //        onChange={(data) => {
                                        //            setOpenChangeConfirmDialog(true);
                                        //            openConfirmDialog({ ...row, herdsManConfirmId: data.value, herdsManConfirmName: data.label});
                                        //        }}
                                        //        options={herdsman.map(item => {return {label: item.name, value: item.id}})}
                                        //        noOptionsMessage={() => "Không tồn tại"}
                                        //        // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                                        //        menuPortalTarget={document.body}
                                        //        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        //    />
                                        //</span>
                                    }
                                </td>
                                <td>
                                    {
                                        row.herdsmanDeliveryName ?
                                            <span>{row.herdsmanDeliveryName}
                                                {
                                                    row.herdsmanDeliveryPhone ? <> (<a href={`tel:${row.herdsmanDeliveryPhone}`}>{row.herdsmanDeliveryPhone})</a></> : <></>
                                                }
                                            </span> :
                                        <span style={{width: "100%", display: "inline-block", textAlign: "center"}}>
                                            --
                                        </span>
                                    }
                                </td>
                                <td><span>{row.description}</span></td>
                                <td>
                                    {row.allowEdit &&
                                    <div className='d-flex'>
                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                            title="Sửa"
                                            onClick={() => {openEditDialog(row)}}
                                        >
                                            <i className="far fa-edit"></i>
                                        </button>
                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                            title="Xóa"
                                            onClick={() => {
                                                setOpenDeleteDialog(true);
                                                openConfirmDialog(row);
                                            }}
                                        >
                                            <i className="far fa-trash-alt"></i>
                                        </button>
                                    </div>
                                    }
                                </td>
                            </tr>
                        )) :
                        <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                    }
                </tbody>
            </DataTableSarang>
        </>
    }

    const renderListItemForMobile = () => {
        return <>
            <div className="accrodion-regular">
                <MobileHeaderSarang
                    headCell={headCellsListCuisineOrder}
                    handleSortChange={(sortExpression) => getListCuisineOrderManagement(page + 1, rowsPerPage, sortExpression, searchData)}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                />
                <div id="accordion">
                    <div className="card">
                        <div className="d-flex align-items-center">
                            <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                                data-toggle="collapse" aria-expanded="false" >
                                <div className="d-flex w-100">
                                    <div>Tổng SL đơn: <b>{sumaryCuisine?.sumaryCuisineOrder}</b> - Trưa: <b>{sumaryCuisine?.sumaryLunchOrder}</b> - Chiều: <b>{sumaryCuisine?.sumaryDinnerOrder}</b></div>
                                    <hr/>
                                    <div>Tổng suất ăn: <b>{sumaryCuisine?.sumaryCuisineQuatity}</b> - Trưa: <b>{sumaryCuisine?.sumaryLunch}</b> - Chiều: <b>{sumaryCuisine?.sumaryDinner}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        data.length > 0 ?
                        data.map((item, index) => (
                            <div className="card" key={index}>
                                <div className="d-flex align-items-center" id={`heading-${item.id}`}>
                                    <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{border: "none"}}
                                        data-toggle="collapse" data-target={`#collapse-${item.id}`} aria-expanded="false" aria-controls={`collapse-${item.id}`}>
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{item.orderCode}</h5>
                                            <small className='d-flex align-items-center'>
                                                {optionsReceiptTime.find(option => option.value === item.receiptTime)?.label}/
                                                {dayjs(item.orderDate).format("DD/MM/YYYY")}
                                            </small>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='mt-0'><b>Ace đặt:</b></div>
                                                <span>{item.herdsManOrderName}</span>
                                                <div className='mt-2'><b>Điện thoại:</b></div>
                                                {
                                                    item.herdsManOrderPhoneNumber ?
                                                    <span>
                                                        <a href={`tel:${item.herdsManOrderPhoneNumber}`}>{item.herdsManOrderPhoneNumber}</a>
                                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table phone ml-2" data-toggle="tooltip" data-placement="top"
                                                            title="Gọi"
                                                            onClick={() => {
                                                                window.location.href = `tel:${item.herdsManOrderPhoneNumber}`;
                                                            }}
                                                        >
                                                            <i className="fas fa-phone"></i>
                                                        </button>
                                                    </span> :
                                                    <br/>
                                                }
                                                <div className='mt-2'><b>Số lượng: </b> {item.quatity}</div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='mt-0'>
                                                    <b>Tình trạng: </b>
                                                    <span>
                                                        {
                                                            item.orderStatus >= 20 ?
                                                                (
                                                            <img title="Đã xác nhận" 
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick" onClick={
                                                                    (e) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                    //setOpenDeactiveDialog(true);
                                                                    //setOpenChangeConfirmDialog(true);
                                                                    //openConfirmDialog(item);
                                                                }}
                                                            />
                                                        ) : (
                                                            <img title="Chưa xác nhận" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                    alt="Cancel" onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                        if (item.allowConfirm) {
                                                                            setOpenChangeConfirmDialog(true);
                                                                            openConfirmDialog(item);
                                                                        }
                                                                        else {
                                                                            ShowNotification("Ae/Ce không có quyền xác nhận", NotificationMessageType.Warning);
                                                                        }
                                                                    }}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                                {/* <br/> */}
                                                <div className='mt-2'>
                                                    <b>Trạng thái: </b>
                                                    {
                                                        (!item.allowChangeStatus) ? <span>{item.orderStatusName}</span> :
                                                        <span>
                                                            <Select
                                                                value={item?.orderStatus ? {label: item.orderStatusName, value: item.orderStatus} : null}
                                                                placeholder="Chọn Trạng thái"
                                                                onChange={(data) => {
                                                                    setOpenChangeStatusDialog(true);
                                                                    openConfirmDialog({ ...item, orderStatusChange: data.value, orderStatusNameChange: data.label});
                                                                }}
                                                                // options={
                                                                //     item.orderStatus < 40 ?
                                                                //         optionsCuisineOrderStatus.filter(x => x.value <= 40 && x.value >= item?.orderStatus) :
                                                                //         optionsCuisineOrderStatus.filter(x => x.value > 40 && x.value >= item?.orderStatus) 
                                                                // }
                                                                //    options={
                                                                //        item?.orderStatus <= 20 ?
                                                                //        optionsCuisineOrderStatus.filter(x=>x.value===30 || x.value>=100) :
                                                                //            optionsCuisineOrderStatus.filter(x => x.value >= 45 && x.value >= item?.orderStatus)  //&& x.value !== item.orderStatus
                                                                //    }
                                                                options={optionsCuisineOrderStatus.filter(x => ((x.value === 30 || x.value >= 50) && x.value !== item.orderStatus) && x.value >= item?.orderStatus)}
                                                                noOptionsMessage={() => "Không tồn tại"}
                                                                // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        </span>
                                                    }
                                                </div>
                                                <div className='mt-0'><b>Hình thức giao:</b></div>
                                                <span>{item.deliveryType==0 ? "Giao nhận" : "Tự lấy"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <i className="fas fa-angle-down p-2"></i>
                                </div>
                                <div id={`collapse-${item.id}`} className="collapse" aria-labelledby={`heading-${item.id}`} data-parent="#accordion">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-6'>
                                                {/*<div className='mt-0'><b>Quận/ huyện:</b></div>*/}
                                                {/*<span>{item.districtName}</span>*/}
                                                <div className='mt-0'><b>Địa chỉ:</b></div>
                                                <span>{item.address}</span>
                                            </div>
                                            <div className='col-6'>
                                                <div className='mt-0'><b>Ace Xác nhận:</b></div>
                                                <span>{item.herdsManConfirmName}</span>
                                                <div className='mt-0'><b>Ace giao:</b></div>
                                                <span>
                                                    {
                                                        item.herdsmanDeliveryName ?
                                                            <span>{item.herdsmanDeliveryName} &nbsp;
                                                                {
                                                                    item.herdsmanDeliveryPhone ? 
                                                                    <>
                                                                        (<a href={`tel:${item.herdsmanDeliveryPhone}`}>{item.herdsmanDeliveryPhone}</a>)
                                                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table phone ml-2" data-toggle="tooltip" data-placement="top"
                                                                            title="Gọi"
                                                                            onClick={() => {
                                                                                window.location.href = `tel:${item.herdsmanDeliveryPhone}`;
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-phone"></i>
                                                                        </button>
                                                                    </> : 
                                                                    <></>
                                                                }
                                                            </span> :
                                                        <span style={{width: "100%", display: "inline-block"}}>
                                                            --
                                                        </span>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div className='mt-0'><b>Ghi chú:</b></div>
                                        <span>{item.description}</span>
                                        {item.allowEdit &&
                                        <div className='d-flex'>
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                title="Sửa"
                                                onClick={() => {openEditDialog(item)}}
                                            >
                                                <i className="far fa-edit"></i>
                                            </button>
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                                title="Xóa"
                                                onClick={() => {
                                                    setOpenDeleteDialog(true);
                                                    openConfirmDialog(item);
                                                }}
                                            >
                                                <i className="far fa-trash-alt"></i>
                                            </button>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )) : <div className="card">Chưa có dữ liệu</div>
                    }
                </div>
                {
                    totalItemCount > 0 ?
                    <DataTablePagination
                        totalItemCount={totalItemCount}
                        rowsPerPage={rowsPerPage}
                        pageIndex={page + 1}
                        handleChangePage={handleChangePage}
                    /> :
                    <></>
                }
            </div>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
        </>
    }

    const renderFormSearch = () => {
        return <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-6 col-md-4 col-lg-4 col-xl-4">
                    <label>Từ ngày</label>
                    <DatePicker
                        {...register("orderFromDate")}
                        onChange={(data) => setValue("orderFromDate", dayjs(data).format())}
                        defaultValue={dayjs(now)}
                        className="form-control"
                        format='DD/MM/YYYY'
                        slotProps={{ textField: { size: 'small' } }}
                        //label={"Nhập Ngày nhận"}
                    />
                </div>

                <div className="form-group col-6 col-md-4 col-lg-4 col-xl-4">
                    <label>Đến ngày</label>
                    <DatePicker
                        {...register("orderToDate")}
                        onChange={(data) => setValue("orderToDate", dayjs(data).format())}
                        className="form-control"
                        format='DD/MM/YYYY'
                        slotProps={{ textField: { size: 'small' } }}
                    //label={"Nhập Ngày nhận"}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label>Thời gian</label>
                    <Select
                        {...register("receiptTime", {})}
                        placeholder="Thời gian nhận"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("receiptTime", data ? data.value : null)}
                        options={optionsReceiptTime}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Ace shipper</label>
                    <Select
                        isClearable
                        {...register("deliveryHerdsmanId", {})}
                        placeholder="Ace Shipper"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("deliveryHerdsmanId", data ? data.value : null)}
                        options={herdsmanGv.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không tồn tại"}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                    {/*
                    <label>Phường/ Xã</label>
                    <Select
                        isDisabled={commune.length == 0}
                        isClearable
                        {...register("communeId", {})}
                        placeholder="Chọn Phường/ Xã"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("communeId", data ? data.value : null)}
                        options={commune.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không tồn tại"}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                    */ }
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input-department-general">Ban/Đội/Nhóm</label>
                    <DepartmentTreeSelect
                        {...register("departmentId", {})}
                        onChange={(data) => setValue("departmentId", data ? data.value : null)}
                        placeholder="Chọn Ban"
                        options={DepartmentHelper.RenderOptions(departmentAll)}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input-department-general">Ace Swat</label>
                    <Select
                        isClearable
                        {...register("herdsmanId", {})}
                        placeholder="Ace Swat"
                        onChange={(data) => setValue("herdsmanId", data ? data.value : null)}
                        options={herdsmanAll.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không tồn tại"}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <label htmlFor="input-department-general">Trạng thái</label>
                    <Select
                        isClearable
                        placeholder="Trạng thái"
                        {...register("status", {})}
                        onChange={(data) => {
                            setValue("status", data ? data.value : null);
                        }}
                        options={optionsCuisineOrderStatus}
                        noOptionsMessage={() => "Không tồn tại"}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>                
                <div className="form-group col-md-4">
                    <label htmlFor="input-department-general">Quận/huyện</label>
                    <Select
                        isClearable
                        {...register("districtId", {})}
                        placeholder="Chọn quận/huyện"
                        onChange={(data) => {
                            setValue("districtId", data ? data.value : null);
                            setSelectedDistrict(data || null);
                        }}
                        options={district.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không tồn tại"}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input-address" className="col-form-label">Địa chỉ</label>
                    <input
                        id="address"
                        className="form-control"
                        type="text"
                        name="address"
                        placeholder="Địa chỉ"
                        ref={register()}
                    />
                </div>
                

            </div>
            <FormSearchFunctionButtons>
                <div className="row">
                    <div className="col-sm-12 pl-0">
                        <p className="text-center">
                            <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                            <button className="btn btn-space btn-secondary">Xóa lọc</button>
                            <button
                                className="btn btn-space btn-warning"
                                onClick={(e) => {
                                    e.preventDefault();
                                    openAddDialog();
                                }}
                            >Thêm mới</button>
                        </p>
                    </div>
                </div>
            </FormSearchFunctionButtons>
        </form>
    }
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    isDesktopOrLaptop ? renderTableForDesktop() : <></>
                }
                
                {
                    isTabletOrMobile ? renderListItemForMobile() : <></>
                }
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>

            <ModalSubmitForm
                title={!selectedItem ? "Đăng ký mới suất ăn" : "Chỉnh sửa đăng ký suất ăn"}
                open={isOpenAddEditDialog}
                onClose={closeAddEditDialog}
            >
                <FormAddEditCuisineOrder
                    district={district}
                    //province={province}
                    herdsman={herdsman}
                    // ===
                    updateItem={selectedItem}
                    onSubmitAddEdit={handleSubmitAddEditCuisineOrder}
                />
            </ModalSubmitForm>

            <ModalConfirm
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Bạn có chắc chắn muốn xóa không?":
                    isOpenActiveDialog ? "Bạn có chắc chắn muốn xác nhận không?":
                    isOpenDeactiveDialog ? "Bạn có chắc chắn muốn hủy xác nhận không?": 
                    isOpenChangeStatusDialog ? `Bạn có chắc chắn muốn thay đổi trạng thái thành <b>"${selectedItem?.orderStatusNameChange}"</b>?`:
                    isOpenChangeConfirmDialog ? `Bạn có chắc chắn muốn bản ghi đăng ký suất này ăn được xác nhận bởi a/c/e <b>"${selectedItem?.herdsManConfirmName}"</b>?` :
                    isOpenChangeDeliveryDialog ? `Bạn có chắc chắn muốn bản ghi đăng ký suất này được giao vận bởi a/c/e <b>"${selectedItem?.herdsManDeliveryName}"</b>?` : ""
                }
                open={isOpenDeleteDialog ||
                    isOpenActiveDialog ||
                    isOpenDeactiveDialog  ||
                    isOpenChangeStatusDialog ||
                    isOpenChangeConfirmDialog ||
                    isOpenChangeDeliveryDialog
                }
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
        </div>
    )
}