import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'
import Select from "react-select";
import Popover from '@mui/material/Popover';

import headCellsListHerdsman from './head-cell-list-herdsman';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, getUserInfo, sendReactGaEvent } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";
import * as zionManagementAction from "../../redux/store-sarang/zion/zion-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as groupManagementAction from "../../redux/store-sarang/group/group-management.store";
import * as districtManagementAction from "../../redux/store/district-management/district.store"
import * as positionManagementAction from "../../redux/store-sarang/position/position-management.store";
import * as userManagementAction from "../../redux/store/user-management/user-management.store";
import * as feedbackManagementAction from "../../redux/store-sarang/feedback/feedback-management.store";
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";

import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import dayjs from 'dayjs';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import ModalAlert from '../../components/sarang-modals/modal-alert/modal-alert';

import FormAddEditHerdsman from './components/form-add-edit-herdsman';
import FormSendMessage from './components/form-send-message';
import DepartmentHelper from '../list-department/helper/department-helper';
import cleanAccents from '../../common/replace';
import DepartmentTreeSelect from '../list-department/components/department-tree-select';
import FormSearchWrapperSarang, { FormSearchFunctionButtons } from '../../components/form-search-wrapper-sarang/form-search-wrapper-sarang';
import { useMediaQuery } from 'react-responsive';
import DataTablePagination from '../../components/datatable-sarang/datatable-pagination';
import { changeAlias } from '../../utils/configuration';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function HerdsmanList() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    // -- Data to map with ids
    const userInfo = getUserInfo();
    const [department, setDepartment] = useState([]);
    const [herdsman, setHerdsman] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [district, setDistrict] = useState([]);
    const [zion, setZion] = useState([]);
    const [position, setPosition] = useState([]);
    const [group, setGroup] = useState([]);
    const [user, setUser] = useState();
    const [searchData, setSearchData] = useState();
    const [openSearch, setOpenSearch] = useState(false);

    const [data, setData] = useState([])
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);

    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const buttonOpenSendMessageRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [isOpenCreateAccountDialog, setOpenCreateAccountDialog] = useState(false);
    const [isOpenAlertUserName, setOpenAlertUserName] = useState(false);
    const [isOpenSendMessage, setOpenSendMessage] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const getLookupGroup = async () => {
        try {
            const res = await groupManagementAction.GetLookupGroup();
            if (res && res.content) {
                setGroup(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    const getLookupHerdsman = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman();
            if (res && res.content) {
                setHerdsman(res.content.map(item => { return { label: item.name, value: item.id } }));
            }
        } catch (err) {
            throw err;
        }
    };
    
    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                setDepartment(DepartmentHelper.PrepareSortData(res.content.items));
            }
        } catch (err) {
            throw err;
        }
    };
    
    const getLookupZion = async () => {
        try {
            const res = await zionManagementAction.GetLookupZion();
            if (res && res.content) {
                setZion(res.content);
            }
        } catch (err) {
            throw err;
        }
    };
    
    const getLookupDistrict = async () => {
        try {
            const res = await districtManagementAction.GetLookupDistrict();
            if (res && res.content) {
                setDistrict(res.content);
            }
        } catch (err) {
            throw err;
        }
    };
    
    const getLookupPosition = async () => {
        try {
            const res = await positionManagementAction.GetLookupPosition();
            if (res && res.content) {
                setPosition(res.content);
            }
        } catch (err) {
            throw err;
        }
    };
    
    const fetchData = async () => {
        showLoading(true);
        try {
            await Promise.allSettled([
                getLookupZion(),
                getLookupDepartment(),
                getLookupGroup(),
                getLookupDistrict(),
                getLookupPosition(),
                getLookupHerdsman()
            ]);
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }

    // -- End Data to map with ids

    useEffect(() => {
        getListHerdsmanManagement();
        fetchData();
    }, []);

    const getHerdsmanCustomer = async (herdsmanId) => {
        sendReactGaEvent('Herdsman', 'getHerdsmanCustomer');
        try {
            const res = await customerManagementAction.GetMyCustomer(0, 0, herdsmanId);

            if (res && res.content) {
                setCustomer(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        }
    }
    const getListHerdsmanManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, searchData=undefined) => {
        showLoading(true);
        try {
            const res = await herdsmanManagementAction.GetListHerdsman(pageIndex, pageSize, sortExpression, searchData);
    
            if (res && res.content && res.content.items) {
                setData(res.content.items);
                setTotalItemCount(res.content.totalItemCount);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };
    
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort;
        getListHerdsmanManagement(page + 1, rowsPerPage, sortExpression, searchData);
        //console.log(sortExpression);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order;
        getListHerdsmanManagement(newPage, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order;
        getListHerdsmanManagement(1, event.target.value, sortExpression, searchData);
    };

    const handleSubmitAddEditSwat = async (data) => {
        showLoading(true);
        
        let formData = new FormData();
        //console.log(data);
        for (let key in data) {
            // false hoặc 0 vẫn append vào form
            if(data[key] !== undefined && data[key] !== null && data[key] !== '') formData.append(key, data[key]);
        }

        try {
            let res = null;

            if( !data.id ) {
                res = await herdsmanManagementAction.CreateHerdsman(formData);
            } else {
                res = await herdsmanManagementAction.UpdateHerdsman(formData);
            }

            if ( res && res.content ) {
                let sortExpression = orderBy + ' ' + order;
                getListHerdsmanManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
            showLoading(false);
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }

    const handleSubmitSendMessage = async (data) => {
        showLoading(true);

        let formData = new FormData();
        //console.log(data);
        for (let key in data) {
            // false hoặc 0 vẫn append vào form
            if (data[key] !== undefined && data[key] !== null && data[key] !== '') formData.append(key, data[key]);
        }

        try {
            let res = null;
            res = await feedbackManagementAction.SendMessageToHerdsman(formData);
            if (res) {
                ShowNotification(
                    viVN.Success["SendMessSuccess"],
                    NotificationMessageType.Success
                );
            }
            showLoading(false);
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        }
    }
     
    const [selectedItem, setSelectedItem] = useState(null);
    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };
    const openEditDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const openSendMessageDialog = (item) => {
        setOpenSendMessage(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const closeSendMessageDialog = (item) => {
        setOpenSendMessage(false);
        setSelectedItem(null);
    }
    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setSelectedItem(null);
    }
    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setOpenCreateAccountDialog(false);
        setOpenAlertUserName(false);
        setSelectedItem(null);
    }

    const closeAlertDialog = () => {
        setOpenAlertUserName(false);
    }

    const handleCopy = async () => {
        if (user) {
            navigator.clipboard.writeText(user.user + "/" + user.pwd);
            ShowNotification(
                "Đã copy",
                NotificationMessageType.Success
            )
        }
        else
            ShowNotification(
                "không tồn tại",
                NotificationMessageType.Warning
            )
        
    }
    const handleConfirm = async () => {
        try {
            let userName = null;
            let res = null;

            if( isOpenDeleteDialog ) {
                res = await herdsmanManagementAction.DeleteHerdsman(selectedItem.id);
            } else if (isOpenActiveDialog) {
                res = await herdsmanManagementAction.ActiveHerdsman(selectedItem.id);
            } else if (isOpenDeactiveDialog) {
                res = await herdsmanManagementAction.DeActiveHerdsman(selectedItem.id);
            } else if (isOpenCreateAccountDialog) {
                let formData = new FormData();
                
                //if( selectedItem.email && selectedItem.email.indexOf(" ")<0) {
                //    const splitEmail = selectedItem.email.split("@");
                //    userName = splitEmail[0];
                //} else {
                //    userName = cleanAccents(selectedItem.lastName).replace(/\s/g, '').toLowerCase();
                //}
                userName = cleanAccents(selectedItem.lastName).replace(/\s/g, '').toLowerCase();
                if (selectedItem.id.toString().length == 3)
                    userName = userName + "0" + selectedItem.id;
                else if (selectedItem.id.toString().length == 2)
                    userName = userName + "00" + selectedItem.id;
                else if (selectedItem.id.toString().length == 1)
                    userName = userName + "000" + selectedItem.id;
                else userName = userName + selectedItem.id;
                if ( selectedItem.fullName) formData.append("FullName", selectedItem.fullName);
                formData.append("UserName", userName);
                if ( selectedItem.email) formData.append("Email", selectedItem.email);
                if ( selectedItem.dateOfBirth) formData.append("DateOfBirth", selectedItem.dateOfBirth);
                formData.append("Sex", selectedItem.gender ? true : false);
                if ( selectedItem.address) formData.append("Address", selectedItem.address);
                if ( selectedItem.documentUploadId) formData.append("DocumentUploadId", selectedItem.documentUploadId);
                if ( selectedItem.description) formData.append("Description", selectedItem.description);
                if (selectedItem.phoneNumber) formData.append("PhoneNumber", selectedItem.phoneNumber);

                if (selectedItem.groupId1 === 3 || selectedItem.groupId2 === 3)
                    formData.append("Roles[0]", "GIAOVAN");
                else if (selectedItem.groupId1 === 2 || selectedItem.groupId2 === 2)
                    formData.append("Roles[0]", "NHABEP");
                else
                    formData.append("Roles[0]", "OWNER");
                formData.append("HerdsmanId", selectedItem.id);
                res = await userManagementAction.CreateWithMultiRoles(formData);
            }

            showLoading(false);
            closeConfirmDialog();

            if (res) {
                if (userName) {
                    setUser({
                        pwd: res.content.pwd,
                        user: res.content.usr,
                    });
                    setOpenAlertUserName(true);
                }
                let sortExpression = orderBy + ' ' + order;
                getListHerdsmanManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
            
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }

    const onSubmit = (data) => {
        //console.log(data);
        setSearchData(data);
        let sortExpression = orderBy + ' ' + order;
        setPage(0);
        getListHerdsmanManagement(1, rowsPerPage, sortExpression, data);
        if (isTabletOrMobile)
            setOpenSearch(false);
    }

    const showCusClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCusClose = () => {
        setAnchorEl(null);
    };

    const renderTableForDesktop = () => {
        return <>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
            <DataTableSarang
                // button functions
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                // head cells
                headCells={headCellsListHerdsman}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                // pagination
                totalItemCount={totalItemCount}
                setRowsPerPage={setRowsPerPage}
                pageIndex={page}
                handleChangePage={handleChangePage}
            >
                <tbody>
                    {
                        data.length > 0 ?
                        data.map((row, rowIndex) => (
                            <tr key={rowIndex} style={row.isCaptain ? { backgroundColor: 'lightgrey' } : {}}>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? {fontWeight:700} : {} }
                                ><span>{row["gender"] ? <li className="fas fa-male text-primary"></li> : <li className="fas fa-female text-secondary"></li>} {`${row["firstName"]} ${row["lastName"]}`}</span>
                                    {userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")) && row.chatTelegramId &&
                                    <span>&nbsp;<i className="fab fa-telegram-plane mr-2 text-info" title="Gửi tin nhắn" style={{ cursor: 'pointer' }} onClick={() => {
                                        setOpenSendMessage(true);
                                        openSendMessageDialog(row);
                                    } }></i></span>}
                                </td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span>{row.herdsmanType===1 ? "SWAT" : (row?.herdsmanType===2 ? "T/đồ" : "Khác")}</span></td>
                                {/*<td><span>{row["gender"] ? "Nam" : "Nữ"}</span></td>*/}
                                { /*<td><span>{dayjs(row["dateOfBirth"]).format("DD/MM/YYYY")}</span></td>*/}
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span>{row.departmentName}</span></td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.talentAmount > 0 ? { fontWeight: 700, cursor: 'pointer', textAlign: 'right' } : {}} onClick={(event) => {
                                    getHerdsmanCustomer(row.id);
                                    showCusClick(event);
                                }}>
                                    {row.talentAmount > 0 ? <span style={{color: 'blue', cursor: 'pointer'}}>
                                        {row.talentAmount} </span> : <></>
                                    }
                                </td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span> {row.districtName}</span></td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span>{row["address"]}</span></td>
                                {/*<td><span>{row.groupName1}</span></td>*/}
                                {/*<td><span>{row.groupName2}</span></td>*/}
                                <td><span>{row.email}</span></td>
                                <td><span>{row["phoneNumber"]}</span></td>
                                <td><span>{row["description"]}</span></td>
                                <td>
                                    <span>
                                        {row.isActived ? (
                                            <img title="Đang kích hoạt" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick" onClick={() => {
                                                    setOpenDeactiveDialog(true);
                                                    openConfirmDialog(row);
                                                }}
                                            />
                                        ) : (
                                            <img title="Ngưng kích hoạt" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel" onClick={() => {
                                                        setOpenActiveDialog(true);
                                                        openConfirmDialog(row);
                                                    }}
                                            />
                                        )}
                                    </span>
                                </td>
                                <td>{row.herdsmanTakeCareName}</td>
                                <td>
                                    <div className='d-flex'>
                                        {
                                            !row.userId && row.herdsmanType === 1 &&
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table create-account"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Tạo tài khoản"
                                                onClick={() => {
                                                    setOpenCreateAccountDialog(true);
                                                    openConfirmDialog(row);
                                                }}
                                            >
                                                <i className=" far fa-user"></i>
                                            </button>
                                        }
                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                            title="Sửa"
                                            onClick={() => {openEditDialog(row)}}
                                        >
                                            <i className="far fa-edit"></i>
                                        </button>
                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                            title="Xóa"
                                            onClick={() => {
                                                setOpenDeleteDialog(true);
                                                openConfirmDialog(row);
                                            }}
                                        >
                                            <i className="far fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )) :
                        <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                    }
                </tbody>
            </DataTableSarang>
        </>
    }

    const renderListItemForMobile = () => {
        return <>
            <div className="accrodion-regular">
                <div id="accordion">
                    <div className="card">
                        <div className="d-flex align-items-center">
                            <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                                data-toggle="collapse" aria-expanded="false" >
                                <div className="d-flex w-100">
                                    <label className='d-flex align-items-end justify-content-end' style={{ gap: "10px" }}>
                                        Xem
                                        <select
                                            className="custom-select custom-select-sm form-control form-control-sm"
                                            style={{ width: "50px" }}
                                            value={rowsPerPage}
                                            onChange={handleChangeRowsPerPage}
                                        >
                                            {config.Configs.DefaultPageSizeOption.map((pageSize, index) => (
                                                <option key={index} value={pageSize}>{pageSize}</option>
                                            ))}
                                        </select>
                                        mục
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        data.length > 0 ?
                        data.map((item, index) => (
                            <div className="card" key={index}>
                                <div className="d-flex align-items-center" id={`heading-${item.id}`}>
                                    <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{border: "none"}}
                                        data-toggle="collapse" data-target={`#collapse-${item.id}`} aria-expanded="false" aria-controls={`collapse-${item.id}`}>
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{`${item["firstName"]} ${item["lastName"]}`}</h5>
                                            <small className='d-flex align-items-center'>
                                                ({item.herdsmanType === 1 ? "SWAT" : (item?.herdsmanType === 2 ? "T/đồ" : "Khác")}) - {item["gender"] ? "Nam" : "Nữ"}
                                            </small>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='mt-0'><b>Ban/đội/nhóm:</b></div>
                                                <span>{item.departmentName}</span><br />
                                                <div className='mt-0'><b>SL ta-lâng:&nbsp;</b>
                                                {item.talentAmount > 0 ? <span style={{ color: 'blue', cursor: 'pointer' }}>
                                                    <a onClick={(event) => {
                                                        getHerdsmanCustomer(item.id);
                                                        showCusClick(event);
                                                    }}>
                                                        {item.talentAmount}
                                                        </a> </span> : <></>

                                                    }
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='mt-0'>
                                                    <b>Hoạt động: </b>
                                                    <span>
                                                        {item.isActived ? (
                                                            <img title="Đang kích hoạt" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick" onClick={() => {
                                                                    setOpenDeactiveDialog(true);
                                                                    openConfirmDialog(item);
                                                                }}
                                                            />
                                                        ) : (
                                                            <img title="Ngưng kích hoạt" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                    alt="Cancel" onClick={() => {
                                                                        setOpenActiveDialog(true);
                                                                        openConfirmDialog(item);
                                                                    }}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                                <div className='mt-0'><b><li className=" fas fa-phone text-primary">&nbsp;</li></b>
                                                    <span>{item.phoneNumber}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <i className="fas fa-angle-down p-2"></i>
                                </div>
                                <div id={`collapse-${item.id}`} className="collapse" aria-labelledby={`heading-${item.id}`} data-parent="#accordion">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='mt-2'><b>Bộ chính: </b>
                                                    <span>{item.groupName1}</span>
                                                </div>
                                                <div className='mt-0'><b>Quận/ huyện:</b></div>
                                                <span>{item.districtName}</span>
                                                <div className='mt-0'><b>Địa chỉ:</b></div>
                                                <span>{item.address}</span>
                                            </div>
                                            <div className='col-6'>
                                                <div className='mt-2'><b>Bộ phụ: </b>
                                                    <span>{item.groupName2}</span>
                                                </div>
                                                <div className='mt-0'><b>Email:</b></div>
                                                <span>{item.email}</span>
                                                <div className='mt-2'>
                                                    <b>Người quản lý: </b>{item.herdsmanTakeCareName}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='mt-0'><b>Ghi chú:</b></div>
                                        <span>{item.description}</span>

                                        <div className='d-flex'>
                                            {
                                                !item.userId &&
                                                <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table create-account"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Tạo tài khoản"
                                                    onClick={() => {
                                                        setOpenCreateAccountDialog(true);
                                                        openConfirmDialog(item);
                                                    }}
                                                >
                                                    <i className=" far fa-user"></i>
                                                </button>
                                            }
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                title="Sửa"
                                                onClick={() => {openEditDialog(item)}}
                                            >
                                                <i className="far fa-edit"></i>
                                            </button>
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                                title="Xóa"
                                                onClick={() => {
                                                    setOpenDeleteDialog(true);
                                                    openConfirmDialog(item);
                                                }}
                                            >
                                                <i className="far fa-trash-alt"></i>
                                            </button>
                                            {userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")) && item.chatTelegramId &&
                                                <button className="d-inline-block btn btn-sm btn-info custom-button-table" data-toggle="tooltip" data-placement="top"
                                                title="Gửi tin nhắn"
                                                onClick={() => {
                                                    setOpenSendMessage(true);
                                                    openSendMessageDialog(item);
                                                }}
                                            >
                                                <i className="fab fa-telegram-plane mr-2"></i>
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : <div className="card">Chưa có dữ liệu</div>
                    }
                </div>
                {
                    totalItemCount > 0 ?
                    <DataTablePagination
                        totalItemCount={totalItemCount}
                        rowsPerPage={rowsPerPage}
                        pageIndex={page + 1}
                        handleChangePage={handleChangePage}
                    /> :
                    <></>
                }
            </div>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
        </>
    }

    const renderFormSearch = () => {
        return <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                    <div className="form-group col-md-3 col-sm-6">
                        <div className="form-group">
                            <label htmlFor="input-name" className="col-form-label">Họ tên</label>
                            <input
                                id="name"
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Nhập họ tên"
                                ref={register()}
                            />
                        </div>
                    </div>
                    <div className="form-group col-md-3  col-sm-6">
                        <div className="form-group">
                            <label htmlFor="input-phoneNumber" className="col-form-label">Điện thoại</label>
                            <input
                                id="phoneNumber"
                                className="form-control"
                                type="text"
                                name="phoneNumber"
                                placeholder="Số điện thoại"
                                ref={register()}
                            />
                        </div>
                    </div>
                    {department ? <div className="form-group col-md-3 col-sm-6">
                        <label>Ban/ đội/ nhóm</label>
                        <DepartmentTreeSelect
                            isClearable
                            {...register("departmentId", {})}
                            onChange={(data) => setValue("departmentId", data ? data.value : null)}
                            placeholder="Chọn Ban"
                            options={DepartmentHelper.RenderOptions(department)}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                        :
                        <></>
                    }
                    <div className="form-group col-md-3 col-sm-6">
                        <label>Bộ</label>
                        <Select
                            isClearable
                            {...register("groupId1")}
                            placeholder="Chọn bộ"
                            closeMenuOnSelect={true}
                            onChange={(data) => {
                                setValue("groupId1", data ? data.value : null);
                            }}
                            options={group.map(item => { return { label: item.name, value: item.id } })}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                </div>
                <FormSearchFunctionButtons>
                    <div className="row">
                        <div className="col-sm-12 pl-0">
                            <p className="text-center">
                                <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                                <button className="btn btn-space btn-secondary">Xóa lọc</button>
                                <button
                                    className="btn btn-space btn-warning"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openAddDialog();
                                    }}
                                >Thêm mới</button>
                            </p>
                        </div>
                    </div>
                </FormSearchFunctionButtons>
            </form>
        </>
    }

    return (
        <>
        {
                <Popover
                id='buoc1'
                open={open}
                anchorEl={anchorEl}
                onClose={handleCusClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                    {
                        customer && customer.length > 0 && customer.filter(x => x.status >= 50).length > 0 &&
                        <table className="table table-bordered table-hover dataTable" id="datatables">
                                <thead>
                                    <tr>
                                        <th><b>STT</b></th>
                                        <th><b>Họ và tên</b></th>
                                        <th><b>Giới tính</b></th>
                                        <th><b>Độ tuổi</b></th>
                                        <th><b>Hạng mục</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        customer && customer.length > 0 ?
                                            customer.filter(x => x.status >=50).map((row, rowIndex) =>
                                            (
                                                <tr key={rowIndex}>
                                                    <td>{rowIndex + 1}</td>
                                                    <td>{row.fullName}</td>
                                                    <td>{row.gender == 0 ? "Nữ" : "Nam"}</td>
                                                    <td>{row.departmentName}</td>
                                                    <td>{row.statusName}</td>
                                                </tr>
                                            )) : <tr><td colSpan={5}>Chưa có dữ liệu</td></tr>
                                    }
                                </tbody>
                        </table>
                    }
            </Popover>
            }

            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {
                        isDesktopOrLaptop ? renderTableForDesktop() : <></>
                    }

                    {
                        isTabletOrMobile ? renderListItemForMobile() : <></>
                    }
                </div>
                <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                    Launch modal add edit
                </button>
                <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                    Launch modal confirm
                </button>
                <ModalSubmitForm
                    title={isOpenSendMessage ? "Nhắn tin cho swat" : (!selectedItem ? "Thêm mới swat" : "Chỉnh sửa swat")}
                    open={isOpenAddEditDialog || isOpenSendMessage}
                    onClose={!isOpenSendMessage ? closeAddEditDialog : closeSendMessageDialog}
                >
                    {
                        !isOpenSendMessage ?
                            <FormAddEditHerdsman
                                group={group}
                                // department={department}
                                department={DepartmentHelper.RenderOptions(department)}
                                herdsman={herdsman}
                                zion={zion}
                                district={district}
                                position={position}
                                // ===
                                updateItem={selectedItem}
                                onSubmitAddEdit={handleSubmitAddEditSwat}
                            /> :
                            <FormSendMessage
                                // ===
                                herdsman={selectedItem}
                                onSubmitSendMessage={handleSubmitSendMessage}
                            />
                    }
                </ModalSubmitForm>

                <ModalConfirm
                    title={"Xác nhận"}
                    prompt={
                        isOpenDeleteDialog ? "Bạn có chắc chắn muốn xóa bản ghi này không?" :
                            isOpenActiveDialog ? "Bạn có chắc chắn muốn mở khóa bản ghi này không?" :
                                isOpenDeactiveDialog ? "Bạn có chắc chắn muốn khóa bản ghi này không?" :
                                    isOpenCreateAccountDialog ? "Bạn có chắc chắn muốn tạo tài khoản cho SWAT này không?" : ""
                    }
                    open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog || isOpenCreateAccountDialog}
                    onClose={closeConfirmDialog}
                    onConfirm={handleConfirm}
                />
                <ModalAlert
                    title={"Thông báo"}
                    prompt={
                        isOpenAlertUserName ? "Ae/Ce vui lòng gửi tài khoản cho Ace vừa tạo là {" + user?.user + "/" + user?.pwd + "}" :
                            ""
                    }
                    open={isOpenAlertUserName}
                    onClose={closeAlertDialog}
                    onCopy={handleCopy}
                />
            </div>
        </>
        
    )
}