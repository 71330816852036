import React, { useEffect, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import { headCellsListDepartmentRankConfirm } from './head-cell-list-department';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, sendReactGaEvent } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";

import dayjs from 'dayjs';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import DepartmentHelper from './helper/department-helper';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function DepartmentListConfirmRank() {
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // -- End Data to map with ids

    const [data, setData] = useState([]);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);
    const [options, setOptions] = useState([]);
    const [customer, setCustomer] = useState([]);

    useEffect(() => {
        getListDepartmentManagement();
    }, []);

    const getListDepartmentManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, code="", name = "") => {
        showLoading(true);

        try {
            const res = await departmentManagementAction.GetListDepartment(pageIndex, 500, sortExpression, code.trim(), name.trim());
    
            if (res && res.content && res.content.items) {
                setData(res.content.items.filter(x=>x.rankConfirm!==0)); //rankConfirm
                //setData(DepartmentHelper.PrepareSortData(res.content.items));
                setTotalItemCount(res.content.totalItemCount);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    }

    useEffect(() => {
        if (!data || !data.length || data.length == 0) {
            setOptions([]);
            return;
        }
        setOptions(DepartmentHelper.RenderOptions(data));
    }, [data])
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort;
        getListDepartmentManagement(page + 1, rowsPerPage, sortExpression);
        console.log(sortExpression);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order;
        getListDepartmentManagement(newPage, rowsPerPage, sortExpression);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order;
        getListDepartmentManagement(1, event.target.value, sortExpression);
    };
     
    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };
    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setSelectedItem(null);
    }

    const showCusClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCusClose = () => {
        setAnchorEl(null);
    };

    const getDepartmentCustomer = async (departmentId) => {
        sendReactGaEvent('Department', 'getDepartmentCustomer');
        try {
            const res = await customerManagementAction.GetListByDepartmentBaptized(departmentId);

            if (res && res.content) {
                setCustomer(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        }
    }

    const handleConfirm = async () => {
        try {
            let res = null;
            let messageSuccess = null;

            if( isOpenDeleteDialog ) {
                res = await departmentManagementAction.DeleteDepartment(selectedItem.id);
                messageSuccess = viVN.Success["DeleteSuccess"];
            } else if (isOpenActiveDialog) {
                res = await departmentManagementAction.ConfirmRank({ id: selectedItem.id, rank: selectedItem.rankConfirm });
                messageSuccess = viVN.Success["UpdateSuccess"];
            } else if (isOpenDeactiveDialog) {
                res = await departmentManagementAction.DeActiveDepartment(selectedItem.id);
                messageSuccess = viVN.Success["UpdateSuccess"];
            }

            if ( res ) {
                getListDepartmentManagement();
            }
            ShowNotification(
                messageSuccess,
                NotificationMessageType.Success
            );
            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }

    return (
        <>
        <Popover
                id='showCus'
                open={open}
                anchorEl={anchorEl}
                onClose={handleCusClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                    {
                        customer && customer.length > 0 && customer.filter(x => x.status >= 50).length > 0 &&
                        <table className="table table-bordered table-hover dataTable" id="datatables">
                                <thead>
                                    <tr>
                                        <th><b>STT</b></th>
                                        <th><b>Họ và tên</b></th>
                                        <th><b>Giới tính</b></th>
                                        <th><b>Độ tuổi</b></th>
                                        <th><b>Hạng mục</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        customer && customer.length > 0 ?
                                            customer.filter(x => x.status >=50).map((row, rowIndex) =>
                                            (
                                                <tr key={rowIndex}>
                                                    <td>{rowIndex + 1}</td>
                                                    <td>{row.fullName}
                                                        {
                                                            row.customerType == 1 ? " (La-xa-rơ)" : ""
                                                        }
                                                    </td>
                                                    <td>{row.gender == 0 ? "Nữ" : "Nam"}</td>
                                                    <td>{row.departmentName}</td>
                                                    <td>{row.statusName}</td>
                                                </tr>
                                            )) : <tr><td colSpan={5}>Chưa có dữ liệu</td></tr>
                                    }
                                </tbody>
                        </table>
                    }
            </Popover>
        
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                
                <DataTableSarang
                    // button functions
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    // head cells
                    headCells={headCellsListDepartmentRankConfirm}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                >
                    <tbody>
                        {
                            data.length > 0 ? data.map((item, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>
                                        {item.name}
                                    </td>
                                    <td>
                                        <span>{item.description}</span>
                                    </td>
                                    <td onClick={(event) => {
                                        if (item.talentAmount > 0) {
                                            getDepartmentCustomer(item.id);
                                            showCusClick(event);
                                        }
                                    }}>
                                        {item.talentAmount > 0 ? <span style={{ color: 'blue', cursor: 'pointer' }}>
                                            {item.talentAmount} </span> : <></>
                                        }
                                    </td>
                                    <td>
                                        {item.talentBaptemAmount > 0 ? <span style={{ color: 'blue', cursor: 'pointer' }}>
                                            {item.talentBaptemAmount} </span> : <></>
                                        }
                                    </td>
                                    <td>
                                        <span>{item.currentRank}</span>
                                    </td>
                                    <td>
                                        <span>{item.rankConfirm}</span>
                                    </td>
                                    <td align="center">
                                        <span>
                                            <img
                                                title="Duyệt"
                                                style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick"
                                                onClick={() => {
                                                    setOpenActiveDialog(true);
                                                    openConfirmDialog(item);
                                                }}
                                            />
                                        </span>
                                    </td>
                                </tr>)
                            )
                                :
                                <tr><td colSpan={6}>Chưa có dữ liệu</td></tr>
                        }
                    </tbody>
                </DataTableSarang>
            </div>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>

            <ModalConfirm 
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "AE/CE có chắc chắn muốn xóa bản ghi này không?":
                    isOpenActiveDialog ? "AE/CE có chắc chắn muốn duyệt nâng hạng cho biệt đội này không?":
                    isOpenDeactiveDialog ? "AE/CE có chắc chắn muốn khóa bản ghi này không?": ""
                }
                open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog }
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
            </div>
        </>
    )
}