import React, { useEffect, useState } from 'react';
import * as examinationAction from "../../../redux/store-sarang/examination-report/examination-report-management.store";
import { NotificationMessageType } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import { optionsExamination } from '../../../common/constant';
import Popover from '@mui/material/Popover';

export default function DepartmentRankCupWidget(props) {
    const { userInfo, week,limit } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [loading, setLoading] = useState(false);
    const [listHerdsmanRank, setListHerdsmanRank] = useState([]);
    const [departmentData, setDepartmentData] = useState();

    useEffect(() => {
        GetAllListDepartmentRank()
    }, [week]);

    const GetAllListDepartmentRank = async () => {
        setLoading(true);
        try {
            const res = await examinationAction.GetAllListDepartmentRank(week?.month, week?.year, week?.week);

            if (res && res.content) {
                setListHerdsmanRank(res.content);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const GetDepartmentExaminationReport = async (departmentId) => {
        setLoading(true);
        try {
            const res = await examinationAction.GetDepartmentExaminationReport(departmentId, week?.month, week?.year, week?.week);

            if (res && res.content) {
                setDepartmentData(res.content);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const dptmClick = (event, dptmId) => {
        setAnchorEl(event.currentTarget);
        GetDepartmentExaminationReport(dptmId);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Popover
                id='buoc1'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {/*<Typography sx={{ p: 2 }}>test</Typography>*/}
                {
                    departmentData?.rP_Examination_Report_Dtos && departmentData?.rP_Examination_Report_Dtos.length > 0 &&
                    <table className="table dataTable">
                        <thead>
                            <tr >
                                <td style={{ minWidth: "100px" }}><b>Nội dung</b></td>
                                <td style={{ minWidth: "50px" }}><b>#</b></td>
                                <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                departmentData?.rP_Examination_Report_Dtos.map((item, index) =>
                                    <tr>
                                        <td>{optionsExamination.find(x => x.value == item.status)?.label}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.quantity * item.point}</td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                }
            </Popover>
            <div className="card">
                <h5 className="card-header">Biệt đội ưu tú ({week?.week ? "tuần " + week?.week : "tháng " + week?.month})</h5>
                <div className="card-body">
                    <div className="row text-center">
                        <div className="col-4">
                            {
                                listHerdsmanRank.length > 1 &&
                                <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                    dptmClick(event, listHerdsmanRank[1].departmentId);
                                }}>
                                    <img src="assets/images/rank/Second.png" style={{width: "40px", paddingTop:"10px"}} />
                                        <div style={{ marginTop: "10px", fontWeight: 700 } }>{
                                            listHerdsmanRank[1].departmentName
                                        }</div>
                                        <div style={{ fontWeight: 400}}>(
                                            Hạng: {listHerdsmanRank[1].currentRank}, {listHerdsmanRank[1].talentAmount} ta lâng, {listHerdsmanRank[1].talentBaptemAmount} trái:)</div>
                                    <div style={{ fontWeight: 900, color:"#0867c3" }}>{
                                        Math.round(listHerdsmanRank[1].totalPoint*100)/100
                                    }</div>
                                </div>
                            }
                        </div>
                        <div className="col-4">
                            {
                                
                                listHerdsmanRank.length > 0 &&
                                <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                    dptmClick(event, listHerdsmanRank[0].departmentId);
                                }}>
                                    <img src="assets/images/rank/First.png" style={{width: "50px"}}  />
                                    <div style={{marginTop: "10px", fontWeight:700} }>{
                                            listHerdsmanRank[0].departmentName
                                        }</div>
                                        <div style={{ fontWeight: 400 }}>(
                                            Hạng: {listHerdsmanRank[0].currentRank}, {listHerdsmanRank[0].talentAmount} ta lâng, {listHerdsmanRank[0].talentBaptemAmount} trái:)</div>
                                        <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                                            Math.round(listHerdsmanRank[0].totalPoint * 100) / 100
                                    }</div>
                                </div>
                            }
                        </div>
                        <div className="col-4">
                            {
                                listHerdsmanRank.length > 2 &&
                                <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                    dptmClick(event, listHerdsmanRank[3].departmentId);
                                }}>
                                    <img src="assets/images/rank/Third.png" style={{width: "35px", paddingTop:"15px"}}  />
                                        <div style={{ marginTop: "10px", fontWeight: 700 } }>{
                                            listHerdsmanRank[2].departmentName
                                        }</div>
                                        <div style={{ fontWeight: 400 }}>(
                                            Hạng: {listHerdsmanRank[2].currentRank}, {listHerdsmanRank[2].talentAmount} ta lâng, {listHerdsmanRank[2].talentBaptemAmount} trái:)</div>
                                        <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                                            Math.round(listHerdsmanRank[2].totalPoint * 100) / 100
                                    }</div>
                                </div>
                            }
                        </div>
                        
                    </div>
                    <div className="row text-center">
                        <table className="table dataTable table-hover " id="datatable">
                            <thead>
                                <tr>
                                    <td style={{ minWidth: "50px" }}><b>Xếp Hạng</b></td>
                                    <td style={{ minWidth: "100px" }}><b>Đội</b></td>
                                    <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                    <td style={{ minWidth: "50px" }}><b>Hạng</b></td>
                                    <td style={{ minWidth: "50px" }}><b>Ta-lâng</b></td>
                                    <td style={{ minWidth: "50px" }}><b>Số trái</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listHerdsmanRank.map((item, index) => 
                                        index > 2 && index <= (limit ? limit - 1 : 9) &&
                                            <tr>
                                                <td>{item.rank}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={(event) => {
                                                    dptmClick(event, item.departmentId);
                                                }}>{item.departmentName}</td>
                                                <td>{Math.round(item.totalPoint * 100) / 100}</td>
                                                <td>{item.currentRank}</td>
                                                <td>{item.talentAmount}</td>
                                                <td>{item.talentBaptemAmount}</td>
                                            </tr>
                                    
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <LoadingViewAria loading={loading} />
            </div>
        </>
    )
}