import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import headCellsListDepartment from './head-cell-list-department';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, sendReactGaEvent } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";

import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import dayjs from 'dayjs';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import FormAddEditDepartment from './components/form-add-edit-department';
import DepartmentTreeTableContent from './components/department-tree-table-rows';
import DepartmentHelper from './helper/department-helper';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function DepartmentList() {
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));
    
    // -- End Data to map with ids

    const [data, setData] = useState([]);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);
    const [options, setOptions] = useState([]);
    const [customer, setCustomer] = useState([]);

    useEffect(() => {
        getListDepartmentManagement();
    }, []);

    const getListDepartmentManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, code="", name = "") => {
        showLoading(true);

        try {
            const res = await departmentManagementAction.GetListDepartment(pageIndex, 500, sortExpression, code.trim(), name.trim());
    
            if (res && res.content && res.content.items) {
                // setData(res.content.items);
                setData(DepartmentHelper.PrepareSortData(res.content.items));
                setTotalItemCount(res.content.totalItemCount);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    }

    useEffect(() => {
        if (!data || !data.length || data.length == 0) {
            setOptions([]);
            return;
        }
        setOptions(DepartmentHelper.RenderOptions(data));
    }, [data])
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort;
        getListDepartmentManagement(page + 1, rowsPerPage, sortExpression);
        console.log(sortExpression);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order;
        getListDepartmentManagement(newPage, rowsPerPage, sortExpression);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order;
        getListDepartmentManagement(1, event.target.value, sortExpression);
    };

    const handleSubmitAddEditSwat = async (data) => {
        showLoading(true);
        
        try {
            let res = null;
            let messageSuccess = null;

            if( !data.id ) {
                res = await departmentManagementAction.CreateDepartment(data);
                messageSuccess = viVN.Success["CreateSuccess"];
            } else {
                res = await departmentManagementAction.UpdateDepartment(data);
                messageSuccess = viVN.Success["UpdateSuccess"];
            }

            if ( res && res.content ) {
                getListDepartmentManagement();
            }
            ShowNotification(
                messageSuccess,
                NotificationMessageType.Success
            );
        } catch (err) {
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        } finally {
            showLoading(false);
        }
    }

    const getDepartmentCustomer = async (departmentId) => {
        sendReactGaEvent('Department', 'getDepartmentCustomer');
        try {
            const res = await customerManagementAction.GetListByDepartmentBaptized(departmentId);

            if (res && res.content) {
                setCustomer(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        }
    }
     
    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };
    const openEditDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setSelectedItem(null);
    }
    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setSelectedItem(null);
    }
    const handleConfirm = async () => {
        try {
            let res = null;
            let messageSuccess = null;

            if( isOpenDeleteDialog ) {
                res = await departmentManagementAction.DeleteDepartment(selectedItem.id);
                messageSuccess = viVN.Success["DeleteSuccess"];
            } else if (isOpenActiveDialog) {
                res = await departmentManagementAction.ActiveDepartment(selectedItem.id);
                messageSuccess = viVN.Success["UpdateSuccess"];
            } else if (isOpenDeactiveDialog) {
                res = await departmentManagementAction.DeActiveDepartment(selectedItem.id);
                messageSuccess = viVN.Success["UpdateSuccess"];
            }

            if ( res ) {
                getListDepartmentManagement();
            }
            ShowNotification(
                messageSuccess,
                NotificationMessageType.Success
            );
            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card">
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="input-name" className="col-form-label">Tên</label>
                                        <input id="input-name" placeholder="Tên" type="text" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 pl-0">
                                    <p className="text-center">
                                        <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                                        <button className="btn btn-space btn-secondary">Xóa lọc</button>
                                        <button
                                            className="btn btn-space btn-warning"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openAddDialog();
                                            }}
                                        >Thêm mới</button>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <DataTableSarang
                    // button functions
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    // head cells
                    headCells={headCellsListDepartment}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    // pagination - ko truyền thì ko có
                    // totalItemCount={totalItemCount}
                    // setRowsPerPage={setRowsPerPage}
                    // pageIndex={page}
                    // handleChangePage={handleChangePage}
                >
                    <DepartmentTreeTableContent
                        data={data}
                        setOpenDeactiveDialog={setOpenDeactiveDialog}
                        setOpenActiveDialog={setOpenActiveDialog}
                        setOpenDeleteDialog={setOpenDeleteDialog}
                        openConfirmDialog={openConfirmDialog}
                        openEditDialog={openEditDialog}
                        customer={customer}
                        getDepartmentCustomer={getDepartmentCustomer }
                    />
                </DataTableSarang>
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>
            <ModalSubmitForm
                title={!selectedItem ? "Thêm mới swat" : "Chỉnh sửa swat"}
                open={isOpenAddEditDialog}
                onClose={closeAddEditDialog}
            >
                <FormAddEditDepartment
                    options={options}
                    // ===
                    updateItem={selectedItem}
                    onSubmitAddEdit={handleSubmitAddEditSwat}
                />
            </ModalSubmitForm>

            <ModalConfirm 
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Bạn có chắc chắn muốn xóa bản ghi này không?":
                    isOpenActiveDialog ? "Bạn có chắc chắn muốn mở khóa bản ghi này không?":
                    isOpenDeactiveDialog ? "Bạn có chắc chắn muốn khóa bản ghi này không?": ""
                }
                open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog }
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
        </div>
    )
}