import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListCustomer = (pageIndex = 1, pageSize = 10, sortExpression = "", searchData=undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && sortExpression!="" && params.append("sorting", sortExpression);
    searchData?.fullName && searchData?.fullName !== "" && params.append("fullName", searchData.fullName);
    searchData?.phoneNumber && params.append("phoneNumber", searchData.phoneNumber);
    searchData?.fromYearOfBirth && params.append("fromYearOfBirth", searchData.fromYearOfBirth);
    searchData?.toYearOfBirth && params.append("toYearOfBirth", searchData.toYearOfBirth);
    searchData?.departmentId && params.append("departmentId", searchData.departmentId);
    searchData?.provinceId && params.append("provinceId", searchData.provinceId);
    searchData?.districtId && params.append("districtId", searchData.districtId);
    searchData?.herdsmanId && params.append("herdsmanId", searchData.herdsmanId);
    searchData?.herdsmanName && params.append("herdsmanName", searchData.herdsmanName);
    searchData?.status && params.append("status", searchData.status);
    return service.get(ApiUrl.GetListCustomer, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetListNeedHelp = (pageIndex = 1, pageSize = 10, sortExpression = "", searchData = undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && sortExpression != "" && params.append("sorting", sortExpression);
    searchData?.fullName && searchData?.fullName !== "" && params.append("fullName", searchData.fullName);
    searchData?.phoneNumber && params.append("phoneNumber", searchData.phoneNumber);
    searchData?.fromYearOfBirth && params.append("fromYearOfBirth", searchData.fromYearOfBirth);
    searchData?.toYearOfBirth && params.append("toYearOfBirth", searchData.toYearOfBirth);
    searchData?.departmentId && params.append("departmentId", searchData.departmentId);
    searchData?.provinceId && params.append("provinceId", searchData.provinceId);
    searchData?.districtId && params.append("districtId", searchData.districtId);
    searchData?.herdsmanId && params.append("herdsmanId", searchData.herdsmanId);
    searchData?.herdsmanName && params.append("herdsmanName", searchData.herdsmanName);
    searchData?.status && params.append("status", searchData.status);
    return service.get(ApiUrl.GetListNeedHelpCustomer, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetListByDepartmentBaptized = (departmentId) => {
    const params = new URLSearchParams();
    params.append("departmentBaptizedId", departmentId);
    return service.get(ApiUrl.GetListByDepartmentBaptized, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetMyCustomer = (pageIndex = 1, pageSize = 10, herdsmanId=undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    herdsmanId && params.append("herdsmanId", herdsmanId);
    /*sortExpression && sortExpression != "" && params.append("sorting", sortExpression);*/
    return service.get(ApiUrl.GetMyCustomer, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetSumarize = () => {
    return service.get(ApiUrl.GetSumarizeCustomer).then((res) => { return res }).catch((err) => { throw err });
}
export const GetLookupCustomer = (id) => {
    return service.get(ApiUrl.GetLookupCustomer).then(res => { return res }).catch((err) => { throw err });
}
export const GetDetailCustomer = (id) => {
    return service.get(ApiUrl.GetDetailCustomer.replace("{id}",id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateCustomer = (data) => {
    return service.post(ApiUrl.CreateCustomer, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateCustomer = (data) => {
    return service.put(ApiUrl.UpdateCustomer, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateDepartmentBaptized = (data) => {
    return service.put(ApiUrl.UpdateDepartmentBaptized, data).then((res) => { return res }).catch(err => { throw err });
}

export const DeleteCustomer = (id) => {
    return service.delete(ApiUrl.DeleteCustomer.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentCustomer = (id) => {
    return service.postParams(ApiUrl.DeletePermanentCustomer.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveCustomer = (id) => {
    return service.put(ApiUrl.ActiveCustomer.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveCustomer = (id) => {
    return service.put(ApiUrl.DeActiveCustomer.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const ChangeStatusCustomer = (data) => {
    return service.put(ApiUrl.ChangeStatusCustomer, data).then((res) => { return res }).catch(err => { throw err });
}
export const ConfirmStepCustomer = (data) => {
    return service.put(ApiUrl.ConfirmStepCustomer, data).then((res) => { return res }).catch(err => { throw err });
}
export const SendHelping = (data) => {
    return service.put(ApiUrl.SendHelpingHelpingCustomer, data).then((res) => { return res }).catch(err => { throw err });
}
export const AssignHerdsman = (data) => {
    return service.put(ApiUrl.AssignHerdsmanHelpingCustomer, data).then((res) => { return res }).catch(err => { throw err });
}
export const ReceiptHelping = (id) => {
    return service.put(ApiUrl.ReceiptHelpingCustomer.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const CancelHelping = (id) => {
    return service.put(ApiUrl.CancelHelpingCustomer.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}

export const GetAnuallyReport = () => {
    return service.get(ApiUrl.GetAnuallyReport).then((res) => { return res }).catch((err) => { throw err });
}
export const GetCustomerCountStepReport = (year=2024) => {
    const params = new URLSearchParams();
    params.append("year", year);
    return service.get(ApiUrl.GetCustomerCountStepReport, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetListHerdsmanTalang = (departmentId=undefined) => {
    const params = new URLSearchParams();
    departmentId && params.append("departmentId", departmentId);
    return service.get(ApiUrl.GetListHerdsmanTalang, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetCustomerDiary = (id) => {
    return service.get(ApiUrl.GetCustomerDiary.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}