import React, { useEffect, useState, useRef } from 'react';
import SheepNumberWidget from './component/dashboard-sheep-number/sheep-number-widget';
import IntroduceWidget from './component/dashboard-introduce/introduce-number-widget';
import SheepNumberChart from './component/dashboard-sheep-number/sheep-number-chart';
import CookingRegisterChart from './component/dashboard-cooking-register/cooking-register-chart';
import TransportationChart from './component/dashboard-transport/transport-chart';
import SheepAnuallyReport from './component/dashboard-sheep-number/sheep-anually_report';
import { getUserInfo } from '../../utils/configuration';
import ReactPlayer from 'react-player/lazy';
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";

export default function DashboardHome() {
    //var userRole: string;
    const userInfo = getUserInfo();
    const listMonth = [1,2,3,4,5,6,7,8,9,10,11,12];
    useEffect(() => {
        setTimeout(() => {
            const elements = document.getElementsByClassName("highcharts-credits");
            const elementsArray = Array.from(elements);
            
            elementsArray.forEach(element => {
                element.style.display = "none";
            });
        }, 100);
    }, [])

    return (
        <>
            <div className="row">
                <SheepNumberWidget />
                {/*<div className="col-xl-9 col-lg-9 col-md-9 col-12">*/}
                    
                {/*</div>*/}
                {/*<div className="col-xl-3 col-lg-3 col-md-3 col-12">*/}
                {/*    <IntroduceWidget />*/}
                {/*</div>*/}
            </div>
            
            {/*<SheepNumberChart/>*/}

            {
                userInfo && userInfo.userRoles && (userInfo.userRoles.includes("KHUVUCTRUONG") || userInfo.userRoles.includes("DIAVUCTRUONG") || userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")) ?
                    <SheepAnuallyReport /> : <></>
            }
            
            <div className="row">
                <div className="col-md-6"><CookingRegisterChart listMonth={listMonth} /></div>
                <div className="col-md-6"><TransportationChart listMonth={listMonth} /></div>
            </div>
            <div className="row">
                <div className="col-md-6"><ReactPlayer
                    //url='https://swat-api.sarang.live/Uploads/Media/Audios/Healing_01_Healing_Work_Mission.mp3'
                    url='https://vimeo.com/941816174'
                    config={{
                        vimeo: {
                            playerOptions: {
                                title: true,
                                vimeo_logo: false,
                                }
                        }
                    }}
                    playing={false}
                    controls={true}
                    width='100%'
                    //height='50px'
                /></div>
                <div className="col-md-6"><ReactPlayer
                    url='https://swat-api.sarang.live/Uploads/Media/Audios/Healing_06_Healing_WM.mp3'
                    config={{
                        file: { forceAudio: true }
                    }}
                    playing={false}
                    controls={true}
                    width='100%'
                    height='50px'
                /></div>
            </div>
        </>
    )
}