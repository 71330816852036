const headCellsListHerdsman = [
    {
        id: 'Name',
        numeric: false,
        disablePadding: false,
        label: 'Họ và tên',
        className: 'pt-3 pb-3',
        style: { minWidth: "130px" }
    },
    {
        id: 'herdsmanType',
        numeric: false,
        disablePadding: false,
        label: '',
        className: 'pt-3 pb-3',
    },
    //{
    //    id: 'Gender',
    //    numeric: false,
    //    disablePadding: false,
    //    label: 'Giới tính',
    //    className: 'pt-3 pb-3',
    //},
    //{
    //    id: 'DateOfBirth',
    //    numeric: false,
    //    disablePadding: false,
    //    label: 'Ngày sinh',
    //    className: 'pt-3 pb-3',
    //},
    {
        id: 'DepartmentId',
        numeric: false,
        disablePadding: false,
        label: 'Ban/đội/nhóm',
        className: 'pt-3 pb-3',
        style: { minWidth: "100px" }
    },
    {
        id: 'TalentAmount',
        numeric: false,
        disablePadding: false,
        label: 'ta-lâng',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'DistrictId',
        numeric: false,
        disablePadding: false,
        label: 'Quận/huyện',
        className: 'pt-3 pb-3',
    },
    {
        id: 'Address',
        numeric: false,
        disablePadding: false,
        label: 'Địa chỉ',
        className: 'pt-3 pb-3',
        style: { minWidth: "120px" }
    },
    //{
    //    id: 'GroupId1',
    //    numeric: false,
    //    disablePadding: false,
    //    label: 'Bộ chính',
    //    className: 'pt-3 pb-3',
    //    style: { minWidth: "120px" }
    //},
    //{
    //    id: 'GroupId2',
    //    numeric: false,
    //    disablePadding: false,
    //    label: 'Bộ phụ',
    //    className: 'pt-3 pb-3',
    //},
    {
        id: 'Email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
        className: 'pt-3 pb-3',
    },
    {
        id: 'PhoneNumber',
        numeric: false,
        disablePadding: false,
        label: 'Số điện thoại',
        className: 'pt-3 pb-3',
    },
    {
        id: 'Description',
        numeric: false,
        disablePadding: false,
        label: 'Mô tả',
        className: 'pt-3 pb-3',
        style: { minWidth: "120px" }
    },
    {
        id: 'IsActived',
        numeric: false,
        disablePadding: false,
        label: 'Hoạt động',
        className: 'pt-3 pb-3',
    },
    {
        id: 'PersonManageId',
        numeric: false,
        disablePadding: false,
        label: 'Người quản lý',
        className: 'pt-3 pb-3',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: '',
        className: 'pt-3 pb-3 pl-4',
    },
];

export default headCellsListHerdsman;